import React, { useContext, useState } from "react";
import logo from "../../../assets/images/logo-only.png";
import * as links from "../../../utils/links";
import { LogOut } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import { useChangeUserPassword } from "../../../hooks/useChangePassword";

const ChangePasswordModal = ({ isOpen, onClose }) => {
	const {
		watch,
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const password = watch("password");
	const confirmPassword = watch("confirmPassword");
	const { isPending, mutate, data, reset: resetData } = useChangeUserPassword();

	const onSubmit = (data) => {
		if (password !== confirmPassword) {
			return;
		}
		console.log(data);
		mutate(data);
		setTimeout(() => {
			onClose();
			reset();
			resetData();
		}, 2500);
	};

	return (
		isOpen && (
			<div className="bg-white rounded shadow-lg">
				<form onSubmit={handleSubmit(onSubmit)} className="bg-white flex flex-col text-xs gap-2">
					<div className="flex flex-col gap-2">
						<label htmlFor="password" className=" text-gray-700 text-sm ">
							Nova Senha
						</label>
						<input
							{...register("password", { required: true })}
							type="password"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							id="password"
						/>
						{errors.password && <p className="text-red-500 text-xs italic">Coloque um password.</p>}
					</div>
					<div className="">
						<label htmlFor="confirmPassword" className=" text-gray-700 text-sm ">
							Confirme sua nova senha
						</label>
						<input
							{...register("confirmPassword", { required: true })}
							type="password"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							id="confirmPassword"
						/>
						{errors.confirmPassword && <p className="text-red-500 text-xs italic">Confirme o password</p>}
						{password && confirmPassword && password !== confirmPassword && (
							<p className="text-red-500 text-xs italic">As senhas devem ser idênticas</p>
						)}
					</div>
					<div className="flex items-center justify-center">
						<button
							disabled={isPending}
							className="bg-blue-500 hover:bg-blue-700 text-white  text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="submit"
						>
							{isPending ? "Carregando..." : "Trocar senha"}
						</button>
					</div>
				</form>
				{!isPending && data?.message && (
					<span className="flex items-center text-center justify-center mt-2">{data?.message}</span>
				)}
			</div>
		)
	);
};

const Header = () => {
	const { setDataUser, authInfo } = useContext(AuthContext);
	const { dataUser } = authInfo;
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	return (
		<>
			<header className="px-2 py-2 md:px-4 md:py-2 justify-between z-50 w-full flex">
				<div className="w-16 h-16">
					<Link to={dataUser?.key === "CLIENT" ? links.SIGNIN : links.ADMIN_SIGNIN}>
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<div>
					<div className="flex justify-center items-center gap-4">
						<div className="flex flex-col">
							<button onClick={toggleModal} className="flex items-center gap-2" type="button">
								<span className="rounded-full flex items-center justify-center h-10 w-10 bg-gray-200">
									{dataUser.name
										.split(" ")
										.map((word) => word[0])
										.join("")
										.substring(0, 2)
										.toUpperCase()}
								</span>
							</button>
						</div>
						<button
							type="button"
							className="border border-slate-300 shadow shadow-blue-200 flex gap-2 rounded-sm justify-center items-center p-2"
							onClick={() => {
								navigate(dataUser?.key === "CLIENT" ? links.SIGNIN : links.ADMIN_SIGNIN);
								setDataUser(null);
							}}
						>
							Sair
							<LogOut className="h-4 w-4" />
						</button>
					</div>
					<div className="absolute w-1/5 max-w-md min-w-md -translate-x-2/3">
						<ChangePasswordModal isOpen={isModalOpen} onClose={toggleModal} />
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
