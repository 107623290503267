import { Table } from "antd";
import dayjs from "dayjs";
import { LucideArrowLeftToLine } from "lucide-react";
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as links from "../../../../utils/links";
import { useGetEstoquePorData } from "./searchEstoque.services";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
export function SearchEstoque() {
	const location = useLocation();
	const navigate = useNavigate();
	const { id } = useParams();
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const dataInicio = queryParams.get("dataInicio");
	const dataFim = queryParams.get("dataFim");
	const { data, isLoading } = useGetEstoquePorData({ maquinaId: id });
	const transformedData = data?.map((item) => ({
		...item, // Spread the rest of the item properties
		nomeDaMaquina: item.maquina.nome,
	}));
	const columns = [
		// 	{
		// 		title: "Nome da Maquina",
		// 		dataIndex: "nomeDaMaquina",
		// 		key: "nomeDaMaquina",
		// 		render: (text) => <strong>{text}</strong>, // Customize rendering as needed
		// 	},
		{
			title: <span style={{ textAlign: "center", width: "100%" }}>Saídas - {data?.[0]?.maquina?.nome || ""}</span>,

			dataIndex: "data",
			key: "data",
			render: (data) => (
				<span style={{ textAlign: "center", width: "100%" }}>{dayjs(data).format("DD/MM/YYYY HH:mm:ss")}</span>
			),
		},
	];
	return isLoading ? (
		<LoadingAction />
	) : (
		<>
			<div
				onClick={() => {
					navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
						state: location.state,
					});
				}}
				onKeyDown={() => {
					navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
						state: location.state,
					});
				}}
				className="w-1/2 p-2 flex gap-2 items-center justify-center max-w-md border-slate-500 border shadow rounded-lg cursor-pointer"
			>
				<LucideArrowLeftToLine className="h-4 w-4" />
				Voltar para lista de pagamentos
			</div>
			<Table
				className="overflow-x-auto text-center justify-center items-center"
				columns={columns}
				dataSource={transformedData}
				pagination={false}
				loading={isLoading}
				locale={{
					emptyText:
						"".trim() !== "" ? (
							"-"
						) : (
							<div className="w-fit flex">Não foram encontrados resultados para sua pesquisa.</div>
						),
				}}
			/>
		</>
	);
}
