import { useForgetUserPassword } from "../../hooks/useForgerPassword";
import { Loading } from "../ui/Loading";
import { useState } from "react";

export function Modal({ toggleModal }) {
	const { isPending, mutate, data } = useForgetUserPassword();
	const [email, setEmail] = useState("");
	const handleEmailSend = (email) => {
		if (email.trim() === "") {
			return;
		}
		mutate(email);
	};
	return (
		<>
			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div className="w-full min-w-full h-screen absolute z-40 bg-gray-500/50" onClick={() => toggleModal()} />
			<div
				tabIndex={-1}
				className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-slate-100 outline-blue-400 outline rounded-lg shadow"
			>
				{/* <!-- Modal toggle --> */}

				{/* <!-- Main modal --> */}
				<div
					id="authentication-modal"
					aria-hidden="true"
					className="overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
				>
					<div className="relativew-full max-w-md max-h-full">
						{/* <!-- Modal content --> */}
						<div className="relative  flex flex-col justify-center items-center  dark:bg-gray-700">
							{/* <!-- Modal header --> */}
							<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">Digite seu email</h3>
								{/* <button
									type="button"
									onClick={toggleModal}
									className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
									data-modal-hide="authentication-modal"
								>
									<svg
										className="w-3 h-3"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 14 14"
									>
										<path
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
										/>
									</svg>
								</button> */}
							</div>
							{/* <!-- Modal body --> */}
							<div className="p-4 md:p-5">
								<form className="space-y-4" action="#">
									<div>
										<input
											type="email"
											name="email"
											id="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											tabIndex="0"
											className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
											placeholder="seuemail@email.com"
										/>
									</div>

									<button
										type="button"
										onClick={() => handleEmailSend(email)}
										disabled={isPending}
										className={`w-full min-w-80 justify-center items-center flex text-white ${isPending ? "cursor-not-allowed" : ""} disabled:bg-gray-800/60 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
									>
										{isPending ? (
											<div className="flex justify-center items-center gap-2">
												<span>Enviando...</span>
												<Loading />
											</div>
										) : (
											<span>Enviar nova senha por email</span>
										)}
									</button>
								</form>
								<div className="flex justify-center mt-2">
									{data?.sucess && (
										<span className="text-green-500 font-medium text-sm" id="success-msg">
											{data?.mensagem}
										</span>
									)}
									{data && !data.sucess && (
										<span className="text-red-500 font-medium text-sm" id="error-msg">
											{data?.mensagem}
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
