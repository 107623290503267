import { useQuery } from "@tanstack/react-query";

export function useGetStoreIds(clienteId, token) {
	const fetchStoreIds = async () => {
		console.log("Fetching store IDs with clienteId:", clienteId);
		const res = await fetch(`${process.env.REACT_APP_SERVIDOR}/v2/clientes/store-ids/${clienteId}`, {
			headers: { "x-access-token": token },
		});
		if (!res.ok) {
			console.error("Fetch error with status:", res.status);
			throw new Error(`Error fetching store IDs: ${res.status}`);
		}
		const resJson = await res.json();
		console.log("Fetched store IDs:", resJson);
		return resJson;
	};

	const { data, error, isLoading } = useQuery({
		queryKey: ["store-ids", clienteId],
		queryFn: fetchStoreIds,
	});

	if (error) {
		console.error("useGetStoreIds error:", error);
	}

	return { data, error, isLoading };
}
