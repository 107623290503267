import React from "react";

/**
 * Componente responsável por exibir um valor em forma de caixa resumida
 * @function
 * @param {Object} props properties do componente:
 * @param {String} props.label label do valor exibido
 * @param {Number} props.valor valor a ser exibido
 * @returns {JSX.Element}
 */
export function MiniBoxResume({ label, labelClass, valor, onClick }) {
	return (
		<div onClick={onClick} onKeyDown={onClick} className="flex justify-center items-center">
			<div className={`${labelClass} flex w-20 lg:w-40 justify-end px-2 text-xs md:text-sm`}>{label}</div>
			<div className="text-blue-500 bg-slate-300 rounded-md flex w-3/6 p-2 font-bold text-xs md:text-sm truncate">
				{valor}
			</div>
		</div>
	);
}
