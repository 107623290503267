import { faArrowsRotate, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Table } from "antd";
import ptBR from "antd/es/date-picker/locale/pt_BR";
import "antd/dist/antd.css";
import axios from "axios";
import { LucideArrowLeftToLine } from "lucide-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillDelete, AiFillDollarCircle, AiOutlineEdit } from "react-icons/ai";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import notes from "../../../assets/images/notes.png";
import { AuthContext } from "../../../contexts/AuthContext";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import * as links from "../../../utils/links";
import { MiniBoxResume } from "../../ui/MiniBoxResume";
import { Card, CardContent } from "../../ui/card";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../../ui/select";
import "./PagamentosSearch.css";
const PagamentosSearch = (props) => {
	const location = useLocation();
	const maquinaInfos = location.state;
	const { setDataUser, loading, authInfo, setNotiMessage } = useContext(AuthContext);
	const navigate = useNavigate();
	const token = authInfo?.dataUser?.token;
	const [isLoading, setIsLoading] = useState(false);
	// const [searchText, setsearchText] = useState('');

	const [searchText, setSearchText] = useState("");
	const [listCanals, setListCanals] = useState([]);
	const [estornos, setEstornos] = useState("");
	const [estoque, setEstoque] = useState("");
	const [cash, setCash] = useState("");
	const [total, setTotal] = useState("");
	const [loadingTable, setLoadingTable] = useState(false);
	const [dataInicio, setDataInicio] = useState(null);
	const [dataFim, setDataFim] = useState(null);
	const [dataMaquinas, setDataMaquinas] = useState(null);
	const [maquinaStatus, setMaquinaStatus] = useState(null);
	const [totalPix, setTotalPix] = useState("");
	const [totalDebito, setTotalDebito] = useState("");
	const [totalCredito, setTotalCredito] = useState("");

	const paymentTypeDisplay = {
		bank_transfer: "PIX",
		CASH: "Especie",
		debit_card: "Débito",
		credit_card: "Crédito",
		account_money: "PIX",
	};

	// const []
	const { id } = useParams();
	const { RangePicker } = DatePicker;
	useEffect(() => {
		getData(id);
		// const intervalGetData = setInterval(() => {
		// 	getData(id);
		// }, TIME_TO_AUTO_UPDATE);
		// return () => clearInterval(intervalGetData);
		// getMaquinas(id)
	}, [id]);

	useEffect(() => {
		if (dataFim != null) {
			getPaymentsPeriod(dataInicio, dataFim);
		}
	}, [dataInicio, dataFim]);

	const getData = async (id) => {
		if (id.trim() !== "") {
			setLoadingTable(true);
			const maquinas = await axios.get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
				headers: {
					"x-access-token": token,
					"content-type": "application/json",
				},
			});
			const { status } = maquinas.data.find((item) => item.id === id);
			setMaquinaStatus(status);

			axios
				.get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${id}`, {
					headers: {
						"x-access-token": token,
						"content-type": "application/json",
					},
				})
				.then((res) => {
					setTotalCredito(
						res.data.pagamentos
							.filter((item) => item.tipo === "credit_card")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setTotalDebito(
						res.data.pagamentos
							.filter((item) => item.tipo === "debit_card")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setTotalPix(
						res.data.pagamentos
							.filter((item) => item.tipo === "account_money" || item.tipo === "bank_transfer")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setLoadingTable(false);
					setEstornos(res.data.estornos);
					setCash(res?.data?.cash || 0);
					setEstoque(res?.data?.estoque);
					setTotal(res.data.total);
					if (res.status === 200 && Array.isArray(res.data.pagamentos)) {
						setListCanals(res.data.pagamentos);
					}
				})
				.catch((err) => {
					setLoadingTable(false);
					if ([401, 403].includes(err.response.status)) {
						// setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
						setNotiMessage({
							type: "error",
							message: "A sua sessão expirou, para continuar faça login novamente.",
						});
						setDataUser(null);
					}
				});
		}
	};

	const getMaquinas = (id) => {
		axios
			.get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
				headers: {
					"x-access-token": token,
					"content-type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200 && Array.isArray(res.data)) {
					const maquinasData = res.data.find((item) => item.id === id);
					setDataMaquinas(maquinasData ?? null);
				} else {
					throw new Error();
				}
			})
			.catch((err) => {});
	};

	const getPaymentsPeriod = (dataInicio, dataFim) => {
		if (id.trim() !== "") {
			setLoadingTable(true);
			const url = `${process.env.REACT_APP_SERVIDOR}/pagamentos-periodo/${id}`;
			axios
				.post(
					url,
					{
						dataInicio: `${dataInicio}`,
						dataFim: `${dataFim}`,
					},
					{
						headers: {
							"x-access-token": token,
							"content-type": "application/json",
						},
					}
				)
				.then((res) => {
					setLoadingTable(false);
					setTotalCredito(
						res.data.pagamentos
							.filter((item) => item.tipo === "credit_card")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setTotalDebito(
						res.data.pagamentos
							.filter((item) => item.tipo === "debit_card")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setTotalPix(
						res.data.pagamentos
							.filter((item) => item.tipo === "account_money" || item.tipo === "bank_transfer")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setCash(
						res.data.pagamentos
							.filter((item) => item.tipo === "CASH")
							.reduce((acc, item) => acc + Number(item.valor), 0)
					);
					setEstornos(res.data.estornos);
					setTotal(res.data.total);
					if (res.status === 200 && Array.isArray(res.data.pagamentos)) {
						setListCanals(res.data.pagamentos);
					}
				})
				.catch((err) => {
					setLoadingTable(false);
					if ([401, 403].includes(err.response.status)) {
						// setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
						setNotiMessage({
							type: "error",
							message: "A sua sessão expirou, para continuar faça login novamente.",
						});
						setDataUser(null);
					}
				});
		}
	};

	const setFilter = (value) => {
		let dateInicio, dateFim;

		switch (value) {
			case "hoje":
				dateInicio = moment().startOf("day").format("DD/MM/YYYY HH:mm");
				dateFim = moment().endOf("day").format("DD/MM/YYYY HH:mm");
				break;
			case "ontem":
				dateInicio = moment().startOf("day").subtract(1, "days").format("DD/MM/YYYY HH:mm");
				dateFim = moment().subtract(1, "days").endOf("day").format("DD/MM/YYYY HH:mm");
				break;
			case "semana":
				dateInicio = moment().startOf("week").startOf("day").format("DD/MM/YYYY HH:mm");
				dateFim = moment().endOf("day").format("DD/MM/YYYY HH:mm");
				break;
			case "mes":
				dateInicio = moment().startOf("day").startOf("month").format("DD/MM/YYYY HH:mm");
				dateFim = moment().endOf("day").format("DD/MM/YYYY HH:mm");
				break;
			default: {
				const days = Number.parseInt(value, 10);
				dateInicio = moment().subtract(days, "days").startOf("day").format("DD/MM/YYYY HH:mm");
				dateFim = moment().endOf("day").format("DD/MM/YYYY HH:mm");
			}
		}

		setDataInicio(dateInicio);
		setDataFim(dateFim);
		getPaymentsPeriod(dateInicio, dateFim);
	};
	const columns = [
		{
			title: "Data",
			dataIndex: "data",
			key: "data",
			width: 500,
			render: (data) => <span>{moment(data).format("DD/MM/YYYY HH:mm:ss")}</span>,
		},
		{
			title: "Forma de pagamento",
			dataIndex: "tipo",
			key: "tipo",
			render: (tipo, record) => <span>{paymentTypeDisplay[tipo] || ""}</span>,
		},
		{
			title: "Valor",
			dataIndex: "valor",
			key: "valor",
			render: (valor) =>
				new Intl.NumberFormat("pt-BR", {
					style: "currency",
					currency: "BRL",
				}).format(valor),
		},
		{
			title: "Identificador MP",
			dataIndex: "mercadoPagoId",
			key: "mercadoPagoId",
		},
		{
			title: "Estornado",
			dataIndex: "estornado",
			key: "estornado",
			width: 100,
			render: (estornado, record) =>
				estornado ? (
					<OverlayTrigger
						key={record.key}
						placement="top"
						overlay={
							<Tooltip id={`tooltip-top-${record.key}`}>
								{record.motivoEstorno ? record.motivoEstorno : "Sem motivo registrado"}
							</Tooltip>
						}
					>
						<span style={{ color: "gray", cursor: "pointer" }}>{estornado ? "Estornado" : "Recebido"}</span>
					</OverlayTrigger>
				) : (
					<span style={{ color: estornado ? "gray" : "green" }}>{estornado ? "Estornado" : "Recebido"}</span>
				),
		},
	];

	const onRelatorioHandler = () => {
		if (!dataInicio && !dataFim) {
			setNotiMessage({
				type: "error",
				message: "Selecione no calendario a esquerda a data de inicio e firm para gerar o relatorio para essa maquina!",
			});
		} else {
			navigate(`${links.RELATORIO}/${id}`, {
				state: { maquinaInfos, dataInicio, dataFim },
			});
		}
	};

	const valorTotal = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(total);
	const valorEstornos = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(estornos);
	const totalEspecie = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(cash);
	const pulso = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(maquinaInfos.pulso);
	const totalPixFormatado = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(totalPix);
	const totalDebitoFormatado = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(totalDebito);
	const totalCreditoFormatado = Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(totalCredito);

	return (
		<div className="p-2">
			{isLoading && <LoadingAction />}
			<div className="flex flex-col">
				<div className="flex flex-col gap-4 w-full items-center">
					<div name="item-4" className="w-full flex gap-4 justify-between">
						<div className="w-1/2 p-2 flex gap-2 items-center justify-center max-w-md border-slate-500 border shadow rounded-lg">
							<LucideArrowLeftToLine className="h-4 w-4" />
							<Link className="text-base" to={links.DASHBOARD_FORNECEDOR}>
								Lista de Máquinas
							</Link>
						</div>
						<button
							onClick={() => getData(id)}
							type="button"
							className="w-1/2 p-2 flex gap-2 items-center max-w-md justify-center border-slate-500 border shadow rounded-lg"
						>
							<FontAwesomeIcon icon={faArrowsRotate} className="h-4 w-4" />
							<span className="text-base">Atualizar máquina</span>
						</button>
					</div>
					<div name="item-1" className="flex items-center justify-between w-full gap-2">
						<div className="font-bold text-xl text-center truncate w-full shadow rounded-md shadow-blue-500  m-auto p-2">
							{maquinaInfos?.nome}
						</div>
					</div>
					<div name="item-2" className="flex items-center justify-center gap-4 w-full px-2">
						<Button
							className="PagamentosSearch_header_editBtn"
							onClick={() => {
								navigate(`${links.DELETE_FORNECEDOR_CANAIS}/${id}`, {
									state: location.state,
								});
							}}
						>
							<AiFillDelete />
							<span className="text-xs">Excluir Pagamentos</span>
						</Button>
						{/*<Link to={links.REMOTE_CREDIT.replace(':id', id)}>*/}
						{/*   */}
						{/*</Link>*/}
						<Button
							className="PagamentosSearch_header_editBtn"
							onClick={() => {
								navigate(links.REMOTE_CREDIT.replace(":id", id), {
									state: location.state,
								});
							}}
						>
							<AiFillDollarCircle />
							<span className="text-xs">Crédito Remoto</span>
						</Button>
					</div>
					<div name="item-3" className="w-fit flex flex-col gap-2 items-center">
						{/* <span> Filtro por data:</span> */}
						<div className="flex gap-3 justify-center items-center">
							<RangePicker
								showTime={{ defaultValue: [moment().startOf("day"), moment().endOf("day")] }}
								locale={ptBR}
								format={"DD/MM/YYYY HH:mm"}
								value={
									dataInicio && dataFim
										? [moment(dataInicio, "DD/MM/YYYY HH:mm"), moment(dataFim, "DD/MM/YYYY HH:mm")]
										: null
								}
								className="border rounded-md"
								placeholder={["Data Inicial", "Data Final"]}
								onChange={(dates, dateStrings) => {
									setDataInicio(dateStrings[0]);
									setDataFim(dateStrings[1]);
								}}
							/>
							<FontAwesomeIcon
								className="cursor-pointer h-4 w-4"
								icon={faSearch}
								onClick={() => getPaymentsPeriod(dataInicio, dataFim)}
							/>
						</div>
						<Button className="PagamentosSearch_header_editBtn" onClick={() => onRelatorioHandler()}>
							<img src={notes} alt="notes" className="w-4 h-4 mr-2" />
							<span className="text-xs">Gerar Relatório</span>
						</Button>
					</div>

					<div className="flex w-full gap-4 max-w-md">
						<div
							name="item-5"
							className="w-1/2 p-2 flex gap-2 items-center justify-center border-slate-500 border shadow rounded-lg"
						>
							<button
								type="button"
								className="w-fit flex rounded-2xl"
								onClick={() => {
									navigate(`${links.EDIT_FORNECEDOR_CANAIS}/${id}`, {
										state: location.state,
									});
								}}
							>
								<AiOutlineEdit className="w-5 h-5" />
								Editar máquina
							</button>
						</div>

						<span
							className={`w-1/2 p-2 flex gap-2 font-bold items-center justify-center border-slate-500 border shadow rounded-lg ${maquinaStatus === "OFFLINE" ? "bg-red-500 text-white" : "bg-green-500 text-white"}`}
						>
							{maquinaStatus}
						</span>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col items-center gap-4">
						<div className="grid grid-cols-2 w-full gap-2 max-w-md lg:max-w-full lg:grid-cols-2 justify-center items-center ">
							<div className="grid grid-cols-1 gap-2 w-full lg:grid-cols-2">
								<MiniBoxResume valor={totalEspecie} label={"Espécie"} />
								<MiniBoxResume valor={totalPixFormatado} label={"Pix"} />
								<MiniBoxResume valor={totalDebitoFormatado} label={"Debito"} />
								<MiniBoxResume valor={totalCreditoFormatado} label={"Credito"} />
							</div>

							<div className="grid grid-cols-1 gap-2 w-full lg:grid-cols-2">
								<MiniBoxResume valor={valorEstornos} label={"Estornos"} />
								<MiniBoxResume valor={pulso} label={"Pulso"} />
								<MiniBoxResume valor={maquinaInfos.storeId} label={"Store ID"} />
								<button
									className="none cursor-pointer hover:opacity-80"
									type="button"
									onClick={() => {
										navigate(`${links.CLIENTES_MAQUINA_ESTOQUE}/${id}`, { state: location.state });
									}}
								>
									<MiniBoxResume labelClass="underline text-blue-400" valor={estoque ?? ""} label={"Estoque"} />
								</button>
							</div>
						</div>
						{/* {maquinaInfos.storeId && (
							<Link
								className="w-fit"
								target="_blank"
								to={`//www.mercadopago.com.br/stores/detail?store_id=${maquinaInfos.storeId}`}
							>
								<img className="m-auto" src={qr_code_icon} alt="QR" />
							</Link>
						)} */}
						<div className="flex justify-center items-center w-full lg:w-1/2">
							<Select onValueChange={setFilter}>
								<SelectTrigger className="w-48 text-xs h-fit">
									<SelectValue placeholder="Selecione um período" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectLabel>Filtrar por dias</SelectLabel>
										<SelectItem value="7">7 dias atrás</SelectItem>
										<SelectItem value="15">15 dias atrás</SelectItem>
										<SelectItem value="30">30 dias atrás</SelectItem>
									</SelectGroup>
									<SelectGroup>
										<SelectLabel>Filtrar por Períodos</SelectLabel>
										<SelectItem value="hoje">Hoje</SelectItem>
										<SelectItem value="ontem">Ontem</SelectItem>
										<SelectItem value="semana">Essa semana</SelectItem>
										<SelectItem value="mes">Esse Mês</SelectItem>
									</SelectGroup>
								</SelectContent>
							</Select>
							{/* <select
								className="w-48 text-xs h-fit"
								onChange={(e) => {
	@@ -522,7 +561,6 @@ const PagamentosSearch = (props) => {
									<option value="mes">Esse Mês</option>
								</optgroup>
							</select> */}
							<MiniBoxResume valor={valorTotal} label={"Total"} />
						</div>
					</div>
					{/* <div className="flex text-base truncate justify-center">
						<div className="flex flex-col justify-center items-center text-wrap">
							<span className="flex justify-center">{`${maquinaInfos?.nome}`}</span>
							<span>{`${maquinaInfos?.descricao}`}</span>
						</div>
					</div> */}

					<Table
						className="overflow-x-auto hidden sm:block"
						columns={columns}
						dataSource={listCanals}
						pagination={false}
						loading={loadingTable}
						locale={{
							emptyText:
								searchText.trim() !== "" ? (
									"-"
								) : (
									<div className="w-fit flex">Não foram encontrados resultados para sua pesquisa.</div>
								),
						}}
					/>
					{listCanals.length === 0 ? (
						<div className="w-fit sm:hidden flex">Não foram encontrados resultados para sua pesquisa.</div>
					) : (
						<div className="sm:hidden">
							{loadingTable ? (
								<div>Carregando...</div>
							) : (
								<div className="flex flex-col gap-2">
									{listCanals.map((item) => (
										<Card key={item.id} className="shadow shadow-blue-400">
											<CardContent className="flex flex-col py-2 px-4 gap-2">
												<div name="line-1" className="flex gap-4 justify-between">
													<p className="m-0">
														{new Intl.DateTimeFormat("pt-BR", {
															dateStyle: "short",
															timeStyle: "medium",
														}).format(new Date(item.data))}
													</p>
													<p className="border m-0 border-slate-200 bg-blue-200 rounded-lg w-28 text-center font-bold text-sm items-center flex justify-center">
														{paymentTypeDisplay[item.tipo] || ""}
													</p>
												</div>
												<div name="line-2" className="flex gap-4 justify-between">
													{/* <p className="m-0">{item.mercadoPagoId}</p> */}
													<p className="m-0">
														{new Intl.NumberFormat("pt-BR", {
															style: "currency",
															currency: "BRL",
														}).format(item.valor)}
													</p>
													<p
														className={`border m-0 border-slate-200 ${item.estornado ? "bg-red-200" : "bg-green-200"} rounded-lg w-28 text-center font-bold text-sm items-center flex justify-center`}
													>
														{item.estornado ? "Estornado" : "Recebido"}
													</p>
												</div>
											</CardContent>
										</Card>
									))}
									{/* <pre>{JSON.stringify(listCanals, null, 2)}</pre> */}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PagamentosSearch;
