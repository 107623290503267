import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const useGetEstoquePorData = ({ maquinaId }) => {
	const { isLoading, data, error, refetch } = useQuery({
		queryKey: ["maquina-status", maquinaId],
		queryFn: async ({ queryKey }) => {
			// const dataInicio = new Date(queryKey[2]).toISOString().split("T")[0];
			// const dataFim = new Date(queryKey[3]).toISOString().split("T")[0];
			const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/maquina-online/${maquinaId}`);
			return response.data;
		},
	});
	return { isLoading, data, error, refetch };
};
