import React from "react";
import "./Main.css";
import Header from "./Header";

const Main = (props) => {
	const { children } = props;

	return (
		<div className="relative flex h-full flex-col">
			<Header />
			<div className="z-10 w-full">{children}</div>
			{/* <svg
				className="absolute w-full h-full"
				version="1.1"
				id="Camada_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 595.28 841.89"
				preserveAspectRatio="none"
			>
				<style type="text/css"></style>
				<g>
					<path
						fill="#3f53a0"
						d="M-16.52,505.9c22.53,8.72-42.89,63.04,143.26-123.02C231.74,277.94-388.51,37.36,191,131
		C400,164.77,211.88-8.8,433,11c588.75,52.71,113.27,107.84,108.07,109.52C276,205.77,534.27,218.03,555,227.77
		c22.96,10.79,94.65-63.05,109.22-43.95c31.18,40.89,48.25,96.13,21.5,139.43c-10.94,17.71-28.25,31.75-47.1,43.06
		c-34.47,20.68-75.11,33.17-116.6,35.82c-20.89,1.33-42,0.23-62.73,2.9c-52.86,6.8-237.68,692.54,90.7,474.97
		c486-322,170.14-499-229-254c-25.77,15.82-46.83,189.49-78.26,199.89c-46.11,15.25-272.07-23.89-320-14"
					/>
				</g>
			</svg> */}
		</div>
	);
};

/*

<div className={`Main_sidebar ${isOpen ? "open" : ""}`}>
          <button
            type="button"
            className="sidebar-burger"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faXmark} className="icon fa-2x" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="icon fa-2x" />
            )}
          </button>
          <div className={`Main_menuProfile ${isOpen ? "open" : ""}`}>
            <div className="circle-icon">
              <span>LC</span>
            </div>
          </div>

          <Link
            to={
              dataUser?.key === "CLIENT"
                ? links.DASHBOARD_FORNECEDOR
                : links.DASHBOARD_CLIENTES
            }
            className={`Main_menuitemLink ${isOpen ? "open" : ""}`}
          >
            <div className="Main_menuitem">
              <FontAwesomeIcon icon={faServer} className="icon" />
              <div className={`SidebarMaquina ${isOpen ? "open" : ""}`}>
                {dataUser?.key === "CLIENT" ? `Maquinas` : `Clientes`}
              </div>
            </div>
          </Link>
          <div className={`Main_header ${isOpen ? "open" : ""}`}>
            {/* <div className="Main_headerBetween"></div> * /}
            <div
              className="Main_headerRight"
              onClick={() => {
                navigate(
                  dataUser?.key === "CLIENT"
                    ? links.SIGNIN
                    : links.ADMIN_SIGNIN,
                );
                setDataUser(null);
              }}
            >
              {/* <Dropdown overlay={menu} placement="bottomRight" arrow> * /}
              <div className="Main_headerSearch">
                <span style={{ fontSize: "12px" }}>{dataUser.name}</span>
                <FontAwesomeIcon
                  className="icon"
                  style={{ marginLeft: "5px" }}
                  icon={faRightFromBracket}
                ></FontAwesomeIcon>
              </div>
              {/* </Dropdown> * /}
            </div>
          </div>
        </div>

*/

export default Main;
