import { Table } from "antd";
import dayjs from "dayjs";
import { LucideArrowLeftToLine } from "lucide-react";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as links from "../../../../utils/links";
import { useGetEstoquePorData } from "./maquinaStatus.services";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import { AuthContext } from "../../../../contexts/AuthContext";

export function MaquinaStatus() {
	const location = useLocation();
	const navigate = useNavigate();
	const { id } = useParams();
	const { data, isLoading } = useGetEstoquePorData({ maquinaId: id });
	const { authInfo } = useContext(AuthContext);
	console.log(authInfo);

	if (authInfo?.dataUser?.key !== "ADMIN") {
		navigate(links.DASHBOARD_CLIENTES);
	}
	const transformedData = data?.map((item) => ({
		...item, // Spread the rest of the item properties
		nomeDaMaquina: item.maquina.nome,
	}));
	const columns = [
		{
			title: <span style={{ textAlign: "center", width: "100%" }}>Saídas - {data?.[0]?.maquina?.nome || ""}</span>,

			dataIndex: "data",
			key: "data",
			render: (data) => (
				<span style={{ textAlign: "center", width: "100%" }}>{dayjs(data).format("DD/MM/YYYY HH:mm:ss")}</span>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<span style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							width: "16px",
							height: "16px",
							backgroundColor: text === "online" ? "green" : "red",
							borderRadius: "50%",
							marginRight: "8px",
						}}
					/>
					<strong>{text.charAt(0).toUpperCase() + text.slice(1)} </strong>
				</span>
			),
		},
	];
	return isLoading ? (
		<LoadingAction />
	) : (
		<>
			<div
				onClick={() => {
					navigate(`${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`, {
						state: location.state,
					});
				}}
				onKeyDown={() => {
					navigate(`${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`, {
						state: location.state,
					});
				}}
				className="w-1/2 p-2 flex gap-2 items-center justify-center max-w-md border-slate-500 border shadow rounded-lg cursor-pointer"
			>
				<LucideArrowLeftToLine className="h-4 w-4" />
				Voltar para lista de pagamentos
			</div>
			<Table
				className="overflow-x-auto text-center justify-center items-center"
				columns={columns}
				dataSource={transformedData}
				pagination={false}
				loading={isLoading}
				locale={{
					emptyText:
						"".trim() !== "" ? (
							"-"
						) : (
							<div className="w-fit flex">Não foram encontrados resultados para sua pesquisa.</div>
						),
				}}
			/>
		</>
	);
}
