import { useState } from "react";
import "./Auth.css";
// import {Link} from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loginImg from "../../../assets/images/login-2.png";
import { cn } from "../../../utils/utils";
import { Modal } from "../../ui/Modal";
const Auth = (props) => {
	const { authFields, authSubmit, authImage, onsubmit, successMessage, errorMessage } = props;
	const [showPassword, setShowPassword] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};

	return (
		<>
			{modalOpen && <Modal toggleModal={toggleModal} />}
			<div className="Auth_container relavite flex items-center justify-center">
				<div className="h-screen w-full max-w-md flex-col p-5 md:flex-row md:p-28 lg:max-w-full">
					<div className="m-auto flex h-full w-full flex-col rounded-xl bg-gradient-to-b from-blue-300 to-blue-700 shadow-2xl shadow-black lg:max-w-screen-lg lg:flex-row ">
						<div name="login" className="flex w-full flex-1 flex-col items-center gap-3 px-4 py-4">
							<div className="flex max-w-xs flex-col items-center justify-center lg:pt-16">
								<img className="w-60 px-1 lg:w-80" src={authImage} alt="logo" />
							</div>
							<div className="m-auto flex flex-col gap-8">
								<form className="flex flex-col gap-4 md:gap-2" onSubmit={onsubmit}>
									{authFields.map((itemField, indexField) => {
										switch (itemField.type) {
											case "text":
												return (
													<>
														{/* <label
													htmlFor="username"
													className="hidden md:block text-sm font-medium text-gray-900 dark:text-white"
												>
													{itemField.label}
												</label> */}
														<div className={cn("flex", itemField.error && " rounded-lg outline outline-red-400")}>
															<span class="inline-flex items-center rounded-s-md border border-e-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
																<svg
																	className="h-4 w-4 text-gray-500 dark:text-gray-400"
																	aria-hidden="true"
																	xmlns="http://www.w3.org/2000/svg"
																	fill="currentColor"
																	viewBox="0 0 20 20"
																>
																	{/* <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/> */}
																	<path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
																	<path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
																</svg>
															</span>
															<input
																type="text"
																id="username"
																name="username"
																className="block w-full min-w-0 rounded-none rounded-e-lg border border-gray-300 bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
																placeholder={itemField.placeholder ?? ""}
																autoComplete="username"
																value={itemField.value}
																onChange={(event) => {
																	if (typeof itemField.setField === "function") {
																		itemField.setField(event.target.value);
																	}
																}}
																onKeyDown={(event) => {
																	if (event.key === "Enter") {
																		onsubmit();
																	}
																}}
															/>
														</div>
														{/* {<span className={`py-1 ${!itemField.error ? "hidden" : "block"}`}>{itemField.error}</span>} */}
													</>
												);
											case "password":
												return (
													<>
														{/* <label
													htmlFor="password"
													className="hidden md:block text-sm font-medium text-gray-900 dark:text-white"
												>
													{itemField.label}
												</label> */}
														<div className={cn("flex", itemField.error && "rounded-lg outline outline-red-400")}>
															<span class="inline-flex items-center rounded-s-md border border-e-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	className="lucide lucide-key-round h-4 w-4"
																>
																	<title>icon password</title>
																	<path d="M2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4a6.5 6.5 0 1 0-4-4Z" />
																	<circle cx="16.5" cy="7.5" r=".5" fill="currentColor" />
																</svg>
															</span>
															<div className="relative w-full">
																<input
																	className={cn(
																		"block w-full min-w-0 rounded-none rounded-e-lg border border-gray-300 bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
																	)}
																	placeholder={itemField.placeholder ?? ""}
																	id="password"
																	value={itemField.value}
																	type={showPassword ? "text" : "password"}
																	name="password"
																	autoComplete="current-password"
																	onChange={(event) => {
																		if (typeof itemField.setField === "function") {
																			itemField.setField(event.target.value);
																		}
																	}}
																	onKeyDown={(event) => {
																		if (event.key === "Enter") {
																			onsubmit();
																		}
																	}}
																/>
																<button
																	type="button"
																	onClick={() => setShowPassword(!showPassword)}
																	className="absolute right-0 top-0 h-full p-1"
																>
																	{showPassword ? (
																		<FontAwesomeIcon className="size-6" icon={faEyeSlash} />
																	) : (
																		<FontAwesomeIcon className="size-6" icon={faEye} />
																	)}
																</button>
															</div>
														</div>
														{/* {<span className={`py-1 ${!itemField.error ? "hidden" : "block"}`}>{itemField.error}</span>} */}
													</>
												);
											default:
												return null;
										}
									})}
								</form>
								<div className="w-full flex gap-2 flex-col">
									<button
										type="button"
										className="w-fit rounded-3xl bg-gradient-to-b from-green-400 to-green-500 px-24 py-2 text-center text-base font-medium text-white outline-none hover:opacity-80  hover:outline-none focus:outline-none"
										onClick={() => {
											if (typeof onsubmit === "function") {
												onsubmit();
											}
										}}
									>
										{authSubmit}
									</button>
									<button type="button" onClick={toggleModal} className="w-fit m-auto">
										<span className="">Esqueceu sua senha?</span>
									</button>
								</div>
							</div>

							{successMessage && <div className="Auth_successMessage">{successMessage}</div>}
							{errorMessage && (
								<div className="flex rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
									<span className="block sm:inline">{errorMessage}</span>
								</div>
								// <div className="font-bold ">
								//   <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
								// </div>
							)}
						</div>
						<div
							name="image"
							className="flex h-1/2 flex-1 items-center justify-center rounded-xl border-none bg-white outline-none lg:h-full"
						>
							<img
								src={loginImg}
								alt="login"
								loading="lazy"
								className="max-w-full rounded-b-xl object-cover md:rounded-none md:rounded-r-xl"
							/>
						</div>
					</div>
				</div>
				<svg
					className="absolute w-full h-full overflow-auto -z-10"
					version="1.1"
					id="Camada_1"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 600 900"
					preserveAspectRatio="none"
				>
					<title>background</title>
					<g>
						<path
							fill="#3f53a0"
							d="M-16.52,505.9c22.53,8.72-42.89,63.04,143.26-123.02C231.74,277.94-388.51,37.36,191,131
		C400,164.77,211.88-8.8,433,11c588.75,52.71,113.27,107.84,108.07,109.52C276,205.77,534.27,218.03,555,227.77
		c22.96,10.79,94.65-63.05,109.22-43.95c31.18,40.89,48.25,96.13,21.5,139.43c-10.94,17.71-28.25,31.75-47.1,43.06
		c-34.47,20.68-75.11,33.17-116.6,35.82c-20.89,1.33-42,0.23-62.73,2.9c-52.86,6.8-237.68,692.54,90.7,474.97
		c486-322,170.14-499-229-254c-25.77,15.82-46.83,189.49-78.26,199.89c-46.11,15.25-272.07-23.89-320-14"
						/>
					</g>
				</svg>
			</div>
		</>
	);
};

export default Auth;
