import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const useGetEstoquePorData = ({ maquinaId, dataInicio = "", dataFim = "" }) => {
	const { isLoading, data, error, refetch } = useQuery({
		queryKey: ["estoque-por-data", maquinaId, dataInicio, dataFim],
		queryFn: async ({ queryKey }) => {
			const queryParams = dataInicio && dataFim ? `?dataInicio=${dataInicio}&dataFim=${dataFim}` : "";
			// const dataInicio = new Date(queryKey[2]).toISOString().split("T")[0];
			// const dataFim = new Date(queryKey[3]).toISOString().split("T")[0];
			const response = await axios.get(
				`${process.env.REACT_APP_SERVIDOR}/pegar-estoque-por-data/${maquinaId}${queryParams}`
			);
			return response.data;
		},
	});
	return { isLoading, data, error, refetch };
};
