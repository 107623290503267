import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";

export function useChangeUserPassword() {
	const { authInfo } = useContext(AuthContext);
	const token = authInfo?.dataUser?.token;
	const email = authInfo?.dataUser?.email;

	const { mutate, isPending, isError, isSuccess, data, reset } = useMutation({
		mutationFn: async ({ password, confirmPassword }) => {
			console.log(password, confirmPassword);
			const res = await fetch(`${process.env.REACT_APP_SERVIDOR}/trocar-senha`, {
				method: "PUT",
				headers: {
					"x-access-token": token,
					"content-type": "application/json",
				},
				body: JSON.stringify({ email, novaSenha: password, confirmarSenha: confirmPassword }),
			});
			const resJson = await res.json();
			return resJson;
		},
	});

	return { mutate, isPending, isError, isSuccess, data, reset };
}
