import { useMutation } from "@tanstack/react-query";

export function useForgetUserPassword() {
	const { mutate, isPending, isError, isSuccess, data } = useMutation({
		mutationFn: async (email) => {
			const res = await fetch(`${process.env.REACT_APP_SERVIDOR}/esqueci-senha`, {
				method: "PUT",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({ email }),
			});
			const resJson = await res.json();
			return resJson;
		},
	});

	return { mutate, isPending, isError, isSuccess, data };
}
