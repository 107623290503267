import { AuthContext } from "../../contexts/AuthContext";
import { useGetStoreIds } from "../../hooks/useGetStoreIds";
import { Loading } from "./Loading";
import { useContext, useState } from "react";

export function ModalStoreIds({ toggleModal, clientId }) {
	const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
	const { dataUser } = authInfo;
	const token = authInfo?.dataUser?.token;
	const { isPending, mutate, data } = useGetStoreIds(clientId, token);
	return (
		<>
			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div className="w-full min-w-full h-screen absolute z-40 bg-gray-500/50" onClick={() => toggleModal()} />
			<div
				tabIndex={-1}
				className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-slate-100 outline-blue-400 outline rounded-lg shadow"
			>
				{/* <!-- Modal toggle --> */}

				{/* <!-- Main modal --> */}
				<div
					id="authentication-modal"
					aria-hidden="true"
					className="overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
				>
					<div className="relativew-full max-w-md max-h-full">
						{/* <!-- Modal content --> */}
						<div className="relative  flex flex-col justify-center items-center  dark:bg-gray-700">
							{/* <!-- Modal header --> */}
							<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">Store IDS</h3>
							</div>
							{/* <!-- Modal body --> */}
							<div className="p-4 md:p-5">
								<ul className="grid grid-cols-2 gap-4 divide-y divide-gray-200">
									{data?.storeIdsAndNames?.map(({ id, name }) => (
										<li key={id} className="py-1 flex">
											<div className="text-sm text-gray-600">{id}:</div>
											<div className="text-sm text-gray-900 ml-2">{name}</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
